import {useMutation, useQuery} from '@tanstack/vue-query';
import {useVoucherStore} from "@/store/voucher";
import {storeToRefs} from "pinia";

export function getVoucherTypesQuery() {
    const queryKey = ['voucher-types'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/v3/voucher/types").then(response => response?.data || {})
        }
    );
}

export function getVoucherListMutation() {
    return useMutation(
        () => {
            return useNuxtApp().$axios("?route=rest/v3/voucher/vouchersList")
        }
    );
}

export function getAllVoucherTabs() {
    return useMutation(
        () => {
            return useNuxtApp().$axios("?route=rest/v3/voucher/voucher_tabs")
        }
    );
}

export function getAllSuggestedMassages() {
    return useMutation((
        ({tab_id = ""}) => {
            return useNuxtApp().$axios(`?route=rest/v3/voucher/voucher_messages`, {params: {tab_id}})
        }
    ))
}

export function getVoucherDetailsByIdMutation() {
    return useMutation((
        ({id = ""}) => {
            return useNuxtApp().$axios("?route=rest/v3/voucher/voucherDetails", {params: {id}})
        }
    ))
}

export function getAllVoucherTitles() {
    return useMutation((
        ({voucher_order_id = ''}) => {
            return useNuxtApp().$axios("?route=rest/v3/helpdesk/getTicketTitles", {params: {voucher_order_id}})
        }
    ))
}

export function createVoucherTicket() {
    return useMutation((
        (data) => {
            return useNuxtApp().$axios.post("?route=rest/v3/helpdesk/addOrderTicket", data)
        }
    ))
}

export function submitVoucherMutation() {
    return useMutation(
        (formData) => {
            return useNuxtApp().$axios.post("?route=rest/v3/voucher/request", formData)
        }
    );
}

export function checkoutMutation() {
    return useMutation(
        ({cardDetails = "", payment_method = ''} = "") => {
            return useNuxtApp().$axios.post("?route=rest/v3/voucher/modifyPaymentDetails", {
                cardDetails,
                payment_method
            })
        }
    );
}

export function payVoucherMutation() {
    return useMutation(
        (params = '') => {
            return useNuxtApp().$axios.post("?route=rest/v3/voucher/apiCheckout_PAY", {
                ...params,
                cardType: params.type
            })
        }
    );

}

export function getVoucherDetailsByCode() {
    return useMutation((
        (id) => {
            return useNuxtApp().$axios("?route=rest/v3/voucher/voucherDetailsByCode", {params: {code: id}})
        }
    ))
}

export function voucherTapPayMutation() {

    const voucherStore = useVoucherStore()
    const {selectedPayment} = storeToRefs(voucherStore)

    return useMutation(({token = null, cardType = null}) => {
        return useNuxtApp().$axios.post("?route=rest/v3/voucher/apiTapPay_PAY", {
            token,
            cardType: cardType || selectedPayment.value,
            type: selectedPayment.value,
        })
    })
}