<template>
  <div class="py-3 px-4 flex gap-4 justify-between min-h-[70px]">
    <div class="relative flex flex-col items-center">
      <nuxt-link
          :to="$routerUrl('/orders')"
          @click="onClickAccountLink('orders', '')"
      >
        <niceone-image
            :src="$publicPath('/images/account/profile-order-icon.svg')"
            height="54"
            loading="eager"
            width="54"
        />
      </nuxt-link>
      <div class="mt-2">{{ $t("profile.my_orders") }}</div>
    </div>
    <div class="relative flex flex-col items-center">
      <nuxt-link
          :to="$routerUrl('wallet')"
          class="flex-center"
          @click="onClickAccountLink('balance', '')"
      >
        <div v-if="hasUserBalance"
             class="flex-center bg-secondary rounded-full p-1 text-white absolute -top-3 border-2 border-white"
        >
          <niceone-price :price="user.wallet_balance_formatted" class="whitespace-nowrap text-xs font-bold">
            {{ user.wallet_balance_formatted }}
          </niceone-price>
        </div>

        <niceone-image
            :src="$publicPath('/images/account/profile-wallet.svg')"
            height="54"
            loading="eager"
            width="54"
        />
      </nuxt-link>
      <div class="mt-2">{{ $t("profile.balance") }}</div>
    </div>
    <div class="relative flex flex-col items-center">
      <nuxt-link
          :to="$routerUrl('tickets')"
          @click="onClickAccountLink('tickets', '')"
      >
        <niceone-image
            :src="$publicPath('/images/account/profile-ticket.svg')"
            height="54"
            loading="eager"
            width="54"
        />
      </nuxt-link>
      <div class="mt-2">{{ $t("profile.my_tickets") }}</div>
    </div>
    <div class="relative flex flex-col items-center">
      <nuxt-link
          :to="$routerUrl('favourite')"
          @click="onClickAccountLink('favourite', '')"
      >
        <niceone-image
            :src="$publicPath('/images/account/profile-wishlist.svg')"
            height="54"
            loading="eager"
            width="54"
        />
      </nuxt-link>
      <div class="mt-2">{{ $t("profile.favourites") }}</div>
    </div>
  </div>
</template>

<script setup>
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";
import {useAuthStore} from "@/store/auth";

const authStore = useAuthStore();
const {user} = storeToRefs(authStore);

const hasUserBalance = computed(
    () => !!Number(user.value?.wallet_balance_formatted?.replace(/\D/g, ""))
);
const onClickAccountLink = (eventAction, eventLabel = "") => {
  track(TRACKERS.MY_ACCOUNT, {eventAction, eventLabel});
};
</script>
