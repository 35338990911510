<template>
  <van-popup
      v-model:show="model"
      :position="$i18n.locale === 'en' ? 'right' : 'left'"
      :style="{ width: !$device.isDesktop ? '100%' : '500px', height: '100%', zIndex: 9999999 }"
  >
    <simple-wrapper>
      <template #header>
        <van-nav-bar :title="title" class="!bg-light-grey capitalize" @click-left="onClose">
          <template #left>
            <van-icon name="cross" size="28"/>
          </template>
        </van-nav-bar>
      </template>
      <Component :is="activeComponent"/>
    </simple-wrapper>
  </van-popup>
</template>

<script setup>
import TermsAndConditions from "@/components/TermsAndConditions";
import PrivacyAndPolicy from "@/components/PrivacyAndPolicy";
import {useEventBus} from "@vueuse/core";
import {EVENTS} from "@/constants/events";
import {DIALOG_TYPE} from '@/constants/index.js'

const {t} = useI18n();
const components = {
  [DIALOG_TYPE.PRIVACY_AND_POLICY]: PrivacyAndPolicy,
  [DIALOG_TYPE.TERMS_AND_CONDITION]: TermsAndConditions,
};

const selectComponent = ref(DIALOG_TYPE.TERMS_AND_CONDITION)
const activeComponent = computed(() => components[selectComponent.value])

const model = ref(false)
const {on: onTermsDialog} = useEventBus(EVENTS.TERMS_DIALOG);
onTermsDialog(({dialog = true, component = DIALOG_TYPE.TERMS_AND_CONDITION} = {}) => {
  model.value = dialog
  selectComponent.value = component
})

const onClose = () => {
  model.value = false
}

const title = computed(() => selectComponent.value == DIALOG_TYPE.TERMS_AND_CONDITION ? t('auth.terms_and_conditions') : t('pages.privacy_policy'))
</script>