export const LANGUAGE_CODES = {
    EN: 'en',
    AR: 'ar'
}
export const DYNAMIC_COMPONENTS = {
    'PLP_BANNER': 26,
    'CATEGORIES': 27
}

export const LANGUAGES = [
    {
        title: "English",
        code: LANGUAGE_CODES.EN
    },
    {
        title: "العربية",
        code: LANGUAGE_CODES.AR
    }
]

export const MAP_SETTINGS = {
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: false,
    disableDefaultUi: false,
    clickableIcons: false,
    draggable: true
}

export const MAP_ERROR_TYPES = {
    COUNTRY_NOT_SUPPORTED: 'country_not_supported',
    REDBOX_NOT_FOUND: 'redbox_not_found'
}

export const DEFAULT_HEADERS = {
    "platform": "web",
    "versionnumber": "5.0.10",
    "x-oc-currency": "SAR",
    "x-oc-merchant-id": "2afc3973-04a5-4913-83f8-d45b0156b5f1",
    "x-oc-restadmin-id": "c15378d0-04f1-4d36-9af7-ab7e17da918b"
}

export const AUTH_TYPES = {
    LOGIN_PHONE: 'login_phone',
    LOGIN_EMAIL: 'login_email',
    REGISTER: 'register',
    FORGOT: 'forgot'
}

export const WALLET_TYPES = {
    TOTAL: 'total',
    WALLET: 'wallet',
    CREDIT: 'credit',
    ALLOWANCE: 'allowance'
}
export const REWARD_TYPES = {
    ALL: 0,
    PENDING: 1,
    CANCELLED: 2,
    CREDIT: 3,
}
export const REWARD_BANNER_TYPES = {
    BANNER: 1,
    PRODUCT: 2,
}
export const REWARD_BANNER_SOURCE_TYPES = {
    HOME_PAGE: 'Home Rewards Page',
    REWARD_PAGE: 'Offers Rewards Page',
}
export const REDEEM_WALLET_TYPES = {
    NICEONE_WALLET: 0,
    STC_WALLET: 1
}

export const STC_WALLET_TYPES = {
    OTP: "otp",
    PHONE: "phone",
    REVIEW_LIST: "review_list",
    SUCCESS: "success",
}

export const DIALOG_TYPE = {
    PRIVACY_AND_POLICY: "policy",
    TERMS_AND_CONDITION: "terms",
}

export const REWARD_TERM_AND_CONDITIONS = {
    ACCEPT_DIALOG: "accept_dialog",
    TERMS: "terms",
    DESKTOP_TERMS: "desktop_terms",
}

export const VOUCHER_TYPES = {
    GIFT_VOUCHER: 'gift_voucher',
    SEND_GIFTS: 'send_gifts',
    VIDEO: 'video',
    AUDIO: 'audio',
    SHEET: 'sheet',
    SUGGESTED_MESSAGES: 'suggested_messages',
}

export const FORM_STATUS = {
    PASSED: 'passed',
    FAILED: 'failed',
    UN_VALIDATED: 'unvalidated'
}

export const PAYMENT_MODULES = {
    CHECKOUT: 'checkout',
    VOUCHER: 'voucher'
}

export const PAYMENT_CARD_TYPES = {
    TEMPORARY: 'temporary',
    SAVED: 'saved'
}

export const CHECKOUT_POINT_TYPES = {
    ALRAJHI_MOKAFAA: 'alrajhi_mokafaa',
    QITAF: 'qitaf',
    TAMAYOUZ: 'tamayouz',
    LOYALTY: 'niceone_loyalty'
}

export const NOTIFICATION_TABS = {
    MESSAGES: 'messages',
    OFFERS: 'offers'
}

export const NOTIFICATION_ITEM_TYPES = {
    ORDER: 'order',
    TICKETS: 'ticket',
    REPLY: 'reply',
    REVIEW: 'review',
    REVIEW_LIKE: 'review_like',
    LIKE: 'like',
    PRODUCT: 'product',
    FEEDBACK: 'feedback'
}

export const GENDER_TYPES = {
    MALE: 'male',
    FEMALE: 'female',
    PREFER_NOT_TO_SAY: 'prefer_not_to_say'
}

export const NOTIFICATION_ERROR_TYPES = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error'
}

export const LOYALTY_TYPES = {
    SILVER: 1,
    GOLD: 2,
    PLATINUM: 3
}

export const EXPERIMENT_KEYS = {
    VIDEO_OR_BRANDS: 'videos-or-brands-at-home'
}

export const EXPERIMENT_TYPES = {
    CONTROL: 'control',
    TREATMENT: 'treatment'
}

export const BANNER_TYPE_FOR_AUTH = 14

export const BLACKLISTED_PRODUCTS_IDS = []

export const BLACKLISTED_BRANDS_SLUG = []

export const SCHEMA_POSITIONED_PRODUCTS = [24832, 5933, 25632, 3209, 20393, 8741, 20483, 26044, 18874]

export const ONE_YEAR_IN_SECONDS = 60 * 60 * 24 * 365