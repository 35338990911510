<template>
  <van-popup
      :show="isModelShown && !isAppView"
      :style="fullScreenStyle"
      class="!overflow-y-hidden"
      position="center"
      @close="onClose"
  >
    <div class="h-full w-full">
      <a :href="popupItem?.link" target="_blank" @click="onClickPopup">
        <niceone-image :src="popupItem?.image" class="w-full object-cover"
                       :class="[ isFullScreen ? ' h-[100dvh]' : 'h-full']"/>
      </a>
      <div
          class="popup-timer absolute ms-3 top-3 inline-flex items-center bg-[#00000060] text-white rounded-full h-[30px] px-3 cursor-pointer"
          @click="onClosePopup">
        <div class="me-1 text-xs">{{ $t('common.skip') }}</div>
        <van-count-down :time="timer + 1000" @finish="onClose">
          <template #default="timeData">
            <div class="text-white text-xs">{{ timeData.seconds }}</div>
          </template>
        </van-count-down>
      </div>
    </div>

  </van-popup>
</template>

<script setup>
import {useContentStore} from "@/store/content";
import {isValidTimestamp,isAppView} from "@/utilities";
import {TRACKERS} from "@/constants/events";

const contentStore = useContentStore()
const {content, isPopupLoaded, isSmartBannerShown, isCookieBannerShown} = storeToRefs(contentStore)

const popup = computed(() => content.value?.popup || null)
const popupItem = computed(() => popup.value?.length ? popup.value[0] : null)
const isFullScreen = computed(() => popupItem.value?.fullscreen || false)

const fullScreenStyle = computed(() => {
  const {$device} = useNuxtApp();

  //for mobile screen full screen size
  if (isFullScreen.value && $device.isMobile) return {maxHeight: '100dvh', width: '100%', maxWidth: '100%'};

  // default sizes
  return {maxHeight: '75%', width: $device.isDesktop ? '450px' : '85%'}
});

// Use timer from API if available; otherwise, fallback to 10 seconds
const timer = ref((popupItem.value?.timer ?? 10) * 1000);

const isModelShown = computed(() => {
  if (!isValidTimestamp(popupItem.value?.timestamp, false)) return

  const isPopup = popup.value?.length && !isPopupLoaded.value && !isCookieBannerShown.value
  return useNuxtApp().$device.isDesktop ? isPopup : isPopup && !isSmartBannerShown.value
})

const isClickOnPopup = ref(false)
const onClosePopup = () => isPopupLoaded.value = true

const onClickPopup = () => {
  isClickOnPopup.value = true
  onClosePopup()
  track(TRACKERS.CLICK_POPUP_MARKETING, {popup_id: popupItem.value?.id, popup_name: popupItem.value?.en_name})
}

const onClose = () => {
  onClosePopup()
  !isClickOnPopup.value && track(TRACKERS.SKIP_POPUP_MARKETING, {
    popup_id: popupItem.value?.id,
    popup_name: popupItem.value?.en_name
  })
}
</script>