<template>
  <div>
    <div class="flex items-center gap-1 text-xs mb-2">
      <niceone-price :price="cart.offer" all-side-margin color="#997ADB" html-text/>
      <niceone-image v-if="cart?.offer_average === 100" :src="$publicPath('/images/cart-success.svg')"
                     width="15"/>
    </div>
    <van-progress :percentage="cart.offer_average"
                  :show-pivot="false" color="#997adb" pivot-color="#7232dd"
                  pivot-text="Purple"
                  stroke-width="6"/>
  </div>
</template>

<script setup>
import {useCartStore} from '@/store/cart'

const cartStore = useCartStore()
const {cart} = storeToRefs(cartStore)
</script>

<style></style>