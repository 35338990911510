import axios from "axios";
import {DEFAULT_HEADERS} from "@/constants"
import {CURRENCY_CODES} from "@/helper";
import {useJwtToken} from "@/composables/useToken";
import {showNotification} from "@/composables/useNotification";
import {COOKIE_KEYS} from "@/constants/cookie";
import useCustomConfig from "@/composables/useEnvConfig";

export default defineNuxtPlugin(() => {
    const config = useCustomConfig()

    const {$i18n} = useNuxtApp();

    axios.defaults.baseURL = config.public.baseURL;

    const currency = useCookie(COOKIE_KEYS.CURRENCY, {secure: true})?.value || CURRENCY_CODES.SAR
    const session = useCookie(COOKIE_KEYS.SESSION, {secure: true})?.value
    const groupId = useCookie(COOKIE_KEYS.CUSTOMER_GROUP_ID, {secure: true})?.value

    axios.defaults.headers = {
        ...DEFAULT_HEADERS,
        "x-oc-merchant-language": $i18n.locale.value,
        "x-oc-currency": currency,
        "x-oc-session": session,
        "x-customer-group-id": groupId || '',
        "web-layout": useNuxtApp().$device.isDesktop ? 'laptop' : 'mobile'
    }

    const token = useCookie(COOKIE_KEYS.USER_TOKEN, {secure: true})
    if (token.value) {
        axios.defaults.headers['authorization'] = `Bearer ${token.value}`
    }

    try {

        if (process.client) {
            axios.interceptors.request.use((config) => {
                const {getToken} = useJwtToken()
                return getToken().then(jwtToken => {
                    axios.defaults.headers['Verify-Token'] = jwtToken
                    return config
                })
            })

            axios.interceptors.response.use(
                ({data = ""} = {}) => {
                    return data || {}
                },
                error => {

                    const {hideErrorMessage = false, autoClose = 3000} = error?.config || {}

                    if (hideErrorMessage) return

                    if (error?.response?.data?.error && error?.response?.data?.error?.length) {
                        showNotification({messages: error.response.data.error, autoClose})
                    }

                    // Pass errors to bugsnag
                    // useBugsnag().notify(new Error(`Axios Error: ${error?.response?.data?.error}`), event => {
                    //     event.addMetadata('errorDetails', {
                    //         ...error
                    //     });
                    // });

                    return Promise.reject(error);
                }
            );
        }
    } catch (_) {
    }

    return {
        provide: {
            axios,
        },
    };
});