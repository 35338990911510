<template>
  <span :style="{ color: color, fontSize: size, width: size, height: size }" class="svg-icon" v-html="svg"/>
</template>

<script setup>
// https://vitejs.dev/guide/features.html#glob-import
const icons = import.meta.glob('../static/svgs/*.svg', {
  as: 'raw',
  eager: true,
});

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: ''
  }
})

const svg = computed(() => icons[`../static/svgs/${props.name}.svg`])
</script>

<style>
.svg-icon svg {
  width: 100%;
  height: 100%;
}

.svg-icon path {
  fill: currentColor
}
</style>
