<template>
  <div class="flex items-center">
    <template v-for="(part, index) in parsedText" :key="index">
      <svg-icon v-if="part === 'ICON'"
                :style="{ color: color, marginRight: '2px', marginLeft: allSideMargin ? '2px' : 0 }" class="icon-size"
                name="saudi-riyal-symbol"/>
      <span v-else-if="htmlText" v-html="part"></span>
      <span v-else>{{ part }}</span>
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  price: {
    type: [Number, String],
    required: true
  },
  htmlText: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: null
  },
  allSideMargin: {
    type: Boolean,
    default: false
  }
});

const parsedText = computed(() => {
  return props.price?.split(/(SAR|ريال)/g).map(part => part.match(/SAR|ريال/) ? "ICON" : part);
});
</script>

<style scoped>
.icon-size {
  width: .875em;
  height: .875em;
}
</style>