import {DEFAULT_HEADERS} from "@/constants";
import {CURRENCY_CODES} from "@/helper";
import {COOKIE_KEYS} from "@/constants/cookie";

export function useHeaders() {
    const {isDesktop} = useDevice()
    const {locale} = useI18n()
    const currency = useCookie(COOKIE_KEYS.CURRENCY, {secure: true});
    const session = useCookie(COOKIE_KEYS.SESSION, {secure: true});
    const groupId = useCookie(COOKIE_KEYS.CUSTOMER_GROUP_ID, {secure: true});
    const token = useCookie(COOKIE_KEYS.USER_TOKEN, {secure: true});

    const headers = computed(() => ({
        ...DEFAULT_HEADERS,
        "x-oc-merchant-language": locale.value,
        "x-oc-currency": currency.value || CURRENCY_CODES.SAR,
        "x-oc-session": session.value,
        "x-customer-group-id": groupId.value || "",
        "web-layout": isDesktop ? "laptop" : "mobile",
        ...(token.value && {authorization: `Bearer ${token.value}`}),
    }));

    return {headers};
}
