import {COOKIE_KEYS} from "@/constants/cookie";

export const recentSuggestionWord = (keyWord) => {
    const searchSuggestions = useCookie(COOKIE_KEYS.SEARCH_SUGGESTIONS, {secure: true})

    let existingSuggestion = !!searchSuggestions.value ? searchSuggestions.value : []

    if (
        !existingSuggestion.some(obj => obj?.search_query === keyWord?.search_query) &&
        keyWord?.term?.trim() !== '' &&
        keyWord?.search_query?.trim() !== ''
    ) {
        // Add new suggestion at the beginning
        existingSuggestion.unshift(keyWord)
    }

    // Keep only the last 5 suggestions
    searchSuggestions.value = JSON.stringify(existingSuggestion.slice(0, 5))
}