<template>
  <auth-layout :loading="isLoading" :submi-buttont-name="$t('auth.register')" @submit="onRegister">
    <template #title>{{ $t('auth.hello_there_create_new_account') }}</template>

    <div class="rounded-md bg-white">
      <niceone-field
          v-model="name"
          :placeholder="$t('auth.full_name')"
          class="rounded-md"
          type="text"
      ></niceone-field>
      <div
          :style="{ borderBottom: '.5px solid #e5e7eb' }"
          class="flex justify-between items-center mx-4 h-[48px]"
      >
        <div class="text-grey-muted">{{ $t('auth.gender_optional') }}</div>
        <van-radio-group
            v-model="gender"
            class="flex justify-between items-center min-w-[180px]"
            shape="dot"
        >
          <van-radio :name="1">{{ $t('auth.male') }}</van-radio>
          <van-radio :name="2">{{ $t('auth.female') }}</van-radio>
        </van-radio-group>
      </div>
      <mobile-phone-field v-model="phone"/>
      <van-divider class="px-4"/>
      <niceone-field
          v-model="email"
          :placeholder="$t('auth.email')"
          class="rounded-md"
          type="email"
      ></niceone-field>
      <password-field v-model="password"
                      :placeholder="$t('auth.password')" class="rounded-md"/>
      <div class="px-4 py-2 text-xs md:text-sm">
        {{ $t('auth.by_registering_you_accept_our') }}
        <span class="text-blue-500 cursor-pointer"
              @click="emitTermsDialog({component:DIALOG_TYPE.TERMS_AND_CONDITION})">
          {{ $t('auth.terms_and_conditions') }}
        </span>
        <span class="px-2"> {{ $t('common.and') }}
        </span>
        <span class="text-blue-500 cursor-pointer lowercase"
              @click="emitTermsDialog({component:DIALOG_TYPE.PRIVACY_AND_POLICY})">
          {{ $t('auth.privacy_policy') }}
        </span>
      </div>
    </div>

    <template #actions>
      <div
          class="flex cursor-pointer items-center mb-3"
          @click="authStore.changeType(AUTH_TYPES.LOGIN_PHONE)"
      >
        <van-icon :name="$publicPath('/images/auth/email.svg')" size="20"/>
        <div class="mx-2">{{ $t('auth.i_already_have_an_account') }}</div>
      </div>
    </template>
  </auth-layout>
</template>

<script setup>
import AuthLayout from "@/components/AuthLayout";
import MobilePhoneField from "@/components/form/MobilePhoneField";
import {useAuthStore} from "@/store/auth";
import {AUTH_TYPES, DIALOG_TYPE} from "@/constants/index.js";
import {EVENTS, TRACKERS} from "@/constants/events";
import {register} from "@/plugins/API/auth";
import passwordField from "@/components/form/PasswordField"
import {useEventBus} from "@vueuse/core";

const authStore = useAuthStore();

const {emit: emitVerifyDialog} = useEventBus(EVENTS.VERIFY_DIALOG);
const {emit: emitTermsDialog} = useEventBus(EVENTS.TERMS_DIALOG);

const name = ref("");
const email = ref("");
const password = ref("");
const phone = ref("");
const gender = ref(1);

const {isLoading, mutateAsync} = register();
const onRegister = async () => {

  const gcToken = await useNuxtApp().$recaptcha.execute('REGISTER')

  mutateAsync({
    firstname: name.value,
    email: email.value,
    password: password.value,
    gender: gender.value,
    telephone: phone.value,
    gc_token: gcToken
  })
      .then(({data}) => {
        emitVerifyDialog({phone: data.telephone, customer_id: data.customer_id, isRegister: true});
        track(TRACKERS.REGISTRRATION_SUCCESS);
      })
      .catch((error) => {
        track(TRACKERS.REGISTRATION_FAIL, error);
      });
};
</script>
