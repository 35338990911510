import {useHeaders} from "@/composables/useHeaders";
import useCustomConfig from "@/composables/useEnvConfig";

export const useCustomFetch = async (url, params = null) => {

    const config = useCustomConfig()
    const baseURL = config.public.baseURL

    const {headers} = useHeaders()
    
    const {data, pending} = await useLazyFetch(`${baseURL}/${url}`, {
        params,
        headers,
        transform: (data) => JSON.parse(data)?.data
    })

    return {data, loading: pending}
}