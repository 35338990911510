<template>
  <div>
    <van-loading v-if="isLoading" :size="32" class="absolute inset-0 h-full flex-center"/>
    <div v-if="!isLoading">
      <search-list-item :items="items.suggestion" source-name="suggested_keywords" titleKey="term"
                        @close="$emit('close')">
      </search-list-item>
      <search-list-item :items="items.Categories" :redirect-using-search="false" source-name="categories"
                        @close="$emit('close')">
        <template #title>{{ $t('search.relevant_categories') }}</template>
      </search-list-item>
      <search-list-item :items="items.Brands" :redirect-using-search="false" source-name="brands"
                        @close="$emit('close')">
        <template #title>{{ $t('search.relevant_brands') }}</template>
      </search-list-item>
    </div>
  </div>
</template>

<script setup>
import SearchListItem from "@/components/home/SearchListItem"
import {getSearchResults} from "@/plugins/API/search"
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";

const props = defineProps({
  search: {
    type: String,
    default: ''
  }
})

const enabled = computed(() => !!props.search)
const search = computed(() => props.search)
const {isLoading, data} = getSearchResults(
    {
      search: search
    },
    {
      enabled
    })
const items = computed(() => data?.value || {})
watch(items, () => {
  track(TRACKERS.SEARCH_FOR_SUGGESTIONS, props.search)
});
</script>